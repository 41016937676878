<template>
  <v-container>
    <v-row>
      <h1 class="text-subtitle-1 my-3  font-weight-bold">
        Informações do Veiculo
      </h1>
      <v-form
        ref="form"
        lazy-validation
        class="app-form mt-4"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col
            md="4"
            sm="4"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-select
              v-model="autovistoria.vehicle.vehicle_type_id"
              :items="vehicleTypeField.data"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Tipo de Veículo"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="4"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.bodywork_model"
              label="Fabricante/Modelo Carroceria *"
              placeholder="Digite o modelo da carroceria"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="4"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.order_number"
              label="N° Ordem"
              placeholder="Digite o número da ordem"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="6"
            sm="12"
            xs="12"
            lg=""
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.chassi"
              label="Chassi *"
              placeholder="Digite o chassi do veículo"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="6"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.chassi_model"
              label="Fabricante/Modelo Chassi *"
              placeholder="Digite o modelo do chassi*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.renavam"
              label="Renavam*"
              placeholder="Digite o renavam*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="2"
            sm="12"
            xs="12"
            lg="2"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.color"
              label="Cor *"
              placeholder="Digite a cor do veículo*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="3"
            sm="12"
            xs="12"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.complementary_service"
              label="Serviço Complementar"
              placeholder="Digite o serviço complementar"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="3"
            sm="12"
            xs="12"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.free_area"
              label="Área Livre *"
              placeholder="Digite a área livre*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="3"
            sm="12"
            xs="12"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.fuel"
              label="Combustível *"
              placeholder="Digite o combustível*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="3"
            sm="12"
            xs="12"
            lg="3"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.displaced_power"
              label="Potência/Cilindrada *"
              placeholder="Digite a potência*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.operation_area"
              label="Area de Operação"
              placeholder="Digite a area de operação"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.year_model"
              label="Ano/Modelo Carroceria"
              placeholder="Digite o ano da carroceria"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.year_bodybuilding"
              label="Ano de Encarroçamento"
              placeholder="Digite ano de encarroçamento"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.total_passanger"
              label="Total de passageiros *"
              placeholder="Digite o total de passageiros *"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.standing_passenger"
              label="Total de Passageiros em pé *"
              placeholder="Digite o total de passageiros*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.sitting_passenger"
              label="Total de Passageiros Sentados *"
              placeholder="Digite o total de passageiros*"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.tachograph_brand"
              label="Marca Tacógrafo"
              placeholder="Digite a marca tacógrafo"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.tachograph_model"
              label="Ano/Modelo do Tacógrafo"
              placeholder="Digite o modelo do tacógrafo"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="4"
            sm="12"
            xs="12"
            lg="4"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.tachograph_number"
              label="Número do Tacógrafo"
              placeholder="Digite o número do tacógrafo"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
        </v-row>
        <v-divider />
        <h1 class="text-subtitle-1 my-3 mb-5 font-weight-bold">
          Informações do Proprietário
        </h1>
        <v-row>
          <v-col
            md="6"
            sm="12"
            xs="12"
            lg="6"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.owner_name"
              label="Nome do Proprietário"
              placeholder="Digite o nome do proprietário"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
          <v-col
            md="6"
            sm="12"
            xs="12"
            lg="6"
            class="pt-0"
          >
            <v-text-field
              v-model="autovistoria.vehicle.owner_documentation"
              label="Documento do Proprietário"
              placeholder="Digite o documento do proprietário"
              outlined
              dense
              :disabled="canDisabled()"
              color="success"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            depressed
            type="submit"
            :disabled="canDisabled()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-row>
  </v-container>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import PedidoService from '@/services/pedidos.service';
  import GlobalStore from '@/store/modules/global';
  import VehicleTypeService from '@/services/vehicle_type.service'

  export default {
    name: 'TabVehicle',
    props: {
      solicitationId: {
        type:String,
        default: null
      },
    },
    data: () => ({
      loaderForm: false,
      rules: {
        required: (value) => !!value || 'Esse campo deve ser preenchido',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        }
      },
      vehicleTypeField: {
        isLoading: false,
        data: []
      }
    }),
    computed: {
      ...mapState('autovistoria', {
        autovistoria: state => state.detail.data,
        autovistoriaIsLoading: state => state.detail.isLoading
      })
    },
    mounted() {
      this.fetchVehicleTypes()
    },
    methods: {
      ...mapActions('autovistoria', {
        findAutovistoria: 'FIND_AUTOVISTORIA',
      }),
      fetchAutovistoria() {
        this.findAutovistoria({
          solicitationId: this.solicitationId,
          inspectionKey: this.autovistoria.key
        })
      },
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          PedidoService
            .updateVehicleInformation(
              this.solicitationId,
              this.autovistoria.key,
              this.autovistoria.vehicle.key,
              this.autovistoria.vehicle
            )
            .subscribe(res => {
              GlobalStore.setSnackBar({
                show: true,
                message: 'Informações atualizadas com sucesso',
                type: 'success'
              });
              this.fetchAutovistoria()
            }, erro => {
              var msg = [JSON.parse(erro.request.responseText)]
              msg.forEach(m => {
                GlobalStore.setSnackBar({
                  show: true,
                  type: 'error',
                  message: m.errors,
                });
              })
            })
        }
      },
      canDisabled() {
        const unpermittedStatuses = ['deferred', 'undeferred']

        return unpermittedStatuses.includes(this.autovistoria.status.original_value) ||
          !!this.autovistoria.signed_report.content_url
      },
      fetchVehicleTypes() {
        this.vehicleTypeField = { ...this.vehicleTypeField, isLoading: true }
        VehicleTypeService.getAll().subscribe(res => {
          this.vehicleTypeField = {
            ...this.vehicleTypeField,
            isLoading: false,
            data: res
          }
        }, err => {
          this.vehicleTypeField = { ...this.vehicleTypeField, isLoading: false }
        })
      }
    }
  }
</script>
