const checkDate = (value: string) => {
  if (!value) return false;
  if (value.length < 10) return false;

  const splitValue = value.split("/");
  const day = Number(splitValue[0]);
  const month = Number(splitValue[1]);
  const year = Number(splitValue[2]);

  const currentDate = new Date();

  if (day > 31 || day < 1) return false;
  if (month > 12 || month < 1) return false;
  if (
    year < currentDate.getFullYear() - 100 ||
    year > currentDate.getFullYear()
  )
    return false;

  if (
    month > Number(currentDate.getMonth()) + Number(1) &&
    year >= Number(currentDate.getFullYear())
  )
    return false;

  return true;
};

const checkHour = (value: string): boolean =>{
  return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
}

export { checkDate, checkHour };
