<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <v-card class="app-card">
          <v-card-title class="app-card-title mb-0">
            <h4 class="text">
              <span>Detalhes do Pedido</span>
            </h4>
            <v-spacer />
            <v-btn
              v-if="detail.data && detail.data.status === 'AGUARDANDO_ANALISE'"
              depressed
              color="success"
              @click.prevent="doAnalyze"
            >
              Analisar
            </v-btn>
            <!-- <v-btn
              v-if="detail.data && detail.data.status === 'DEFERIDO'"
              depressed
              color="success"
              @click.prevent="doSendArce"
            >
              Enviar pedido para ARCE
            </v-btn> -->
          </v-card-title>
          <v-card-text
            v-if="detail.data"
            class="app-card-content pl-0 pr-0 pb-0"
          >
            <v-row
              no-gutters
              class="status-area"
            >
              <v-col
                :lg="4"
                :md="4"
              >
                <h4>
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                  >
                    <strong class="mr-1">Número:</strong>
                    <span>{{ detail.data.id }}</span>
                  </v-chip>
                </h4>
              </v-col>
              <v-col
                :lg="4"
                :md="4"
              >
                <h4>
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                  >
                    <!-- <strong class="mr-1">Tipo Pedido:</strong> -->
                    <span>{{ detail.data.typeSolicitation.name }}</span>
                  </v-chip>
                </h4>
              </v-col>
              <v-col
                :lg="4"
                :md="4"
              >
                <h4>
                  <v-chip
                    class="ma-2"
                    color="success"
                    outlined
                  >
                    <strong class="mr-1">Situação:</strong>
                    <span>{{
                      detail.data.status | statusPedido
                    }}</span>
                  </v-chip>
                </h4>
              </v-col>
            </v-row>
            <v-card flat>
              <v-card-text class="mt-3">
                <v-row>
                  <v-col
                    :lg="4"
                    :md="4"
                  >
                    <v-text-field
                      label="Empresa"
                      outlined
                      disabled
                      :value="detail.data.company.name"
                    />
                  </v-col>
                  <v-col
                    :lg="4"
                    :md="4"
                  >
                    <v-text-field
                      label="CNPJ"
                      outlined
                      disabled
                      :value="detail.data.company.cnpj"
                    />
                  </v-col>
                  <v-col
                    v-if="detail.data.placa"
                    :lg="4"
                    :md="4"
                  >
                    <v-text-field
                      label="Veículo/Placa"
                      outlined
                      disabled
                      :value="detail.data.placa"
                    />
                  </v-col>
                </v-row>

                <v-tabs color="success">
                  <v-tab>Documentos</v-tab>
                  <v-tab>Acompanhamento</v-tab>
                  <v-tab>Pagamentos</v-tab>
                  <v-tab v-if="canShowTabAutovistoria()">
                    Autovistoria
                  </v-tab>
                  <v-tab-item>
                    <DocumentsList :documentos="detail.data.documents" />
                  </v-tab-item>
                  <v-tab-item>
                    <AcompanhamentoList v-if="detail.data && detail.data.id" />
                  </v-tab-item>
                  <v-tab-item>
                    <PaymentsList :payments="detail.data.payments" />
                  </v-tab-item>
                  <v-tab-item>
                    <AutovistoriaForm :company="detail.data.company" />
                  </v-tab-item>
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-card-text>
          <section
            v-if="detail.isLoading"
            class="isLoading"
          >
            <v-progress-circular
              indeterminate
              color="success"
            />
          </section>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import DocumentsList from './documentos/List';
  import AcompanhamentoList from './acompanhamentos/List';
  import PaymentsList from './pagamentos/List';
  import AutovistoriaForm from './autovistoria/FormAutovistoria.vue'
  import { StatusPedido } from '@/models/status.enum';

  export default {
    components: {
      DocumentsList,
      AcompanhamentoList,
      PaymentsList,
      AutovistoriaForm
    },
    data: () => ({
      dialogCreateCi: {
        open: false,
        data: null
      },
      statusPedido: StatusPedido,
      form: {
        assunto: null,
        viproc: null
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido'
      },
      unpermitted_statuses: [
        'AGUARDANDO_PAGAMENTO',
        'PAGAMENTO_PENDENTE',
        'CANCELADO_POR_FALTA_PAGAMENTO',
        'AGUARDANDO_ANALISE',
        'AGUARDANDO_DOCUMENTACAO',
        'DOCUMENTACAO_PENDENTE',
        'EM_ANALISE'
      ]
    }),
    computed: {
      ...mapState('pedido', ['detail'])
    },
    watch:{
      'detail.data': function (val) {
        if (val.typeSolicitation.key != 'AUTOVISTORIA_LAUDO_ENGENHEIRO_MECANICO' || !val.group) return

        this.showPaymentsGroup(val.group);
      },
    },
    mounted() {
      this.iniData();
    },
    destroyed() {
      this.clearPedido();
      this.clearAutovistorias()
    },
    methods: {
      iniData() {
        const {
          params: { id }
        } = this.$route;

        this.getPedido(id);
      },
      doAnalyze() {
        const { id } = this.detail.data;
        this.updatePedido({ id, status: 'EM_ANALISE' });
      },
      doSendArce() {
        const { id } = this.detail.data;
        this.doSendToArce({ id });
      },
      openDialog(item) {
        this.dialogCreateCi = { ...this.dialogCreateCi, open: true, data: item };
      },
      closeDialog() {
        this.dialogCreateCi = { ...this.dialogCreateCi, open: false, data: null };
        this.form = { ...this.form, assunto: null, viproc: null };
      },
      canShowTabAutovistoria() {
        const { status, typeSolicitation } = this.detail.data
        return typeSolicitation.key === 'AUTOVISTORIA_LAUDO_ENGENHEIRO_MECANICO' && !this.unpermitted_statuses.includes(status)
      },
      ...mapActions('pedido', { getPedido: 'SHOW' }),
      ...mapActions('pedido', { showPaymentsGroup: 'SHOW_PAYMENTS_GROUP' }),
      ...mapActions('pedido', { clearPedido: 'CLEAR_DETAIL' }),
      ...mapActions('pedido', { clearAutovistorias: 'clearAutovistorias' }),
      ...mapActions('pedido', { updatePedido: 'UPDATE_STATUS' }),
      ...mapActions('pedido', { doSendToArce: 'SEND_TO_ARCE' })
    }
  };
</script>

<style lang="scss" scoped>
.isLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  margin: 0px;
}
.status-area {
  border-bottom: solid 1px #e5e5e5;
  h4 {
    text-transform: uppercase;
    flex: 1;
    text-align: center;
    span {
      font-size: 15px;
    }
    strong {
      font-size: initial;
    }
  }
}
</style>
