<template>
  <v-container>
    <v-card
      elevation="0"
      style="height: 700px; overflow-y: scroll;"
    >
      <v-card-text
        v-if="autovistoria"
        class="card-items"
      >
        <ul class="checklist-content-list">
          <li
            v-for="(kind , idx) in autovistoria.checklist.items"
            :key="idx"
            class="checklist-content-list__item"
          >
            <strong class="checklist-content-list__title">{{ kind.name }}</strong>

            <ul class="checklist-content-sublist">
              <li
                v-for="(item , index) in kind.items"
                :key="index"
                :disabled="canDisabled()"
                class="checklist-content-sublist__item"
                two-line
                @click="saveItemChecklist(item)"
              >
                <article class="checklist-content-sublist__content">
                  <strong class="checklist-content-sublist__title">
                    {{ item.name }}
                  </strong>
                  <v-radio-group v-model="item.verified">
                    <v-radio
                      v-if="!canShow(item.name)"
                      :disabled="canDisabled()"
                      color="green"
                      :value="'yep'"
                      label="Sim"
                      class="mr-6"
                    />
                    <v-radio
                      v-if="!canShow(item.name)"
                      :disabled="canDisabled()"
                      color="green"
                      :value="'non'"
                      label="Não"
                      class="mr-6"
                    />
                    <v-radio
                      v-if="canShow(item.name)"
                      :disabled="canDisabled()"
                      color="green"
                      :value="'forward'"
                      label="Dianteira"
                      class="mr-6"
                    />
                    <v-radio
                      v-if="canShow(item.name)"
                      :disabled="canDisabled()"
                      color="green"
                      :value="'rear'"
                      label="Traseira"
                      class="mr-6"
                    />
                    <v-radio
                      :disabled="canDisabled()"
                      color="green"
                      :value="'non_apply'"
                      label="Não se Aplica"
                      class="mr-6"
                    />
                  </v-radio-group>
                </article>
              </li>
            </ul>
          </li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import GlobalStore from '@/store/modules/global';
  import PedidoService from '@/services/pedidos.service';
  import { mapState, mapActions } from 'vuex';
  import { autovistoria } from '@/store/modules/autovistoria';

  export default {
    name: 'TabChecklist',
    props: {
      solicitationId: {
        type:String,
        default: null,
      },
    },
    computed: {
      ...mapState('autovistoria', {
        autovistoria: state => state.detail.data,
        autovistoriaIsLoading: state => state.detail.isLoading
      }),
    },
    methods: {
      ...mapActions('autovistoria', {
        findAutovistoria: 'FIND_AUTOVISTORIA',
      }),
      fetchAutovistoria() {
        this.findAutovistoria({
          solicitationId: this.solicitationId,
          inspectionKey: this.autovistoria.key
        })
      },
      saveItemChecklist(item) {
        const { key, verified, name } = item
        PedidoService
          .updateItemChecklist(this.solicitationId, this.autovistoria.key, key, { verified })
          .subscribe(res => {}, err => {
            GlobalStore.setSnackBar({
              show: true,
              message: `Não possível salvar item, ${name}`,
              type: 'error'
            })
            item.verified = false
          })
      },
      finishChecklist() {
        const { checklist: { key } } = this.autovistoria
        PedidoService
          .finishChecklist(this.solicitationId, this.autovistoria.key, key)
          .subscribe(res => {
            GlobalStore.setSnackBar({
              show: true,
              message: 'Checklist finalizado',
              type: 'success'
            })
            this.fetchAutovistoria()
          }, erro => {
            var msg = [JSON.parse(erro.request.responseText)]
            msg.forEach(m => {
              GlobalStore.setSnackBar({
                show: true,
                type: 'error',
                message: m.errors,
              });
            })
          })
      },
      canDisabled() {
        const unpermittedStatuses = ['awaiting_engineer_analise', 'deferred', 'undeferred']

        return unpermittedStatuses.includes(this.autovistoria.status.original_value) ||
          !!this.autovistoria.signed_report.content_url
      },
      canShow(name) {
        // To-do: ver uma forma no backend para usar um campo que nunca muda ex: code
        return [
          'Catraca/posição',
          'Validador/posição',
          'Plataforma elevatória/posição',
          'Espaço do Cadeirante (sistema de ancoragem)/posição'].includes(name)
      }
    }
  };
</script>
<style scoped lang="scss">
.checklist-content{
  &-list{
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 16px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 20px;
    }
    &__item{
      border-bottom: solid 1px rgba(0,0,0,.1);
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }
}
.checklist-content-sublist{
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  &__item{
    margin-bottom: 15px;
    width: 33%;
  }
}
</style>

