<template>
  <v-container v-if="autovistoria">
    <h1 class="text-subtitle-1 my-3 font-weight-medium">
      Informações do Laudo Técnico
    </h1>
    <!-- Form -->
    <v-form
      ref="form"
      lazy-validation
      class="app-form mt-10"
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col
          md="6"
          sm="12"
          xs="12"
          lg="3"
          class="pt-0"
        >
          <v-text-field
            v-model="autovistoria.inspection_at"
            type="date"
            label="Data de inspeção *"
            placeholder="Digite o modelo da carroceria"
            outlined
            color="success"
            :rules="[rules.required]"
            :disabled="canDisabled()"
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
          xs="12"
          lg="3"
          class="pt-0"
        >
          <v-text-field
            v-model="autovistoria.emission_at"
            type="date"
            label="Data de emissão *"
            placeholder="Digite o data de emissão"
            outlined
            color="success"
            :rules="[rules.required]"
            :disabled="canDisabled()"
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
          xs="12"
          lg="3"
          class="pt-0"
        >
          <v-text-field
            v-model="autovistoria.expiration_at"
            type="date"
            label="Data de vencimento *"
            placeholder="Digite a data de vencimento "
            outlined
            color="success"
            :rules="[rules.required]"
            :disabled="canDisabled()"
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
          xs="12"
          lg="3"
          class="pt-0"
        >
          <v-text-field
            v-model="autovistoria.art"
            label="ART *"
            placeholder="Digite o ART"
            outlined
            color="success"
            :rules="[rules.required]"
            :disabled="canDisabled()"
          />
        </v-col>
        <v-col
          md="6"
          sm="6"
          xs="12"
          lg="6"
        >
          <v-text-field
            :value="autovistoria.engineer_name"
            label="Nome do Engenheiro *"
            placeholder="Digite o nome do engenheiro"
            outlined
            color="success"
            disabled
          />
        </v-col>
        <v-col
          md="6"
          sm="6"
          xs="12"
          lg="6"
        >
          <v-text-field
            :value="autovistoria.engineer_crea"
            label="Crea do Engenheiro *"
            placeholder="Digite o crea do engenheiro"
            outlined
            color="success"
            disabled
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          depressed
          type="submit"
          :disabled="canDisabled()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
      <section
        v-if="loaderForm"
        class="loading"
      >
        <v-progress-circular
          indeterminate
          color="success"
        />
        <span>Salvando...</span>
      </section>
    </v-form>
    <!--/ Form -->

    <v-divider class=" mt-10" />

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-1 mt-5"
    >
      <template v-slot:item.actions="{item}">
        <div v-if="item.value == 'original_report'">
          <v-btn
            v-if="!existsSignedReport() && !canStatus()"
            class="elevation-0 mr-2"
            :loading="loaderbtn"
            color="primary"
            small
            dark
            @click.stop="generateLaudo()"
          >
            <v-icon
              class="mr-3"
              color=""
              medium
            >
              description
            </v-icon>
            Gerar Laudo
          </v-btn>
          <v-btn
            v-if="existsOriginalReport() && !existsSignedReport() && !canStatus()"
            class="elevation-0"
            color="primary"
            :href="autovistoria.original_report.content_url"
            target="_blank"
            small
            dark
          >
            <v-icon
              class="mr-3"
              medium
            >
              download
            </v-icon>
            Visualizar Laudo
          </v-btn>
        </div>
        <div
          v-if="item.value == 'signed_report'"
          bottom
        >
          <v-btn
            v-if="existsSignedReport()"
            class="elevation-0"
            color="primary"
            :href="autovistoria.signed_report.content_url"
            target="_blank"
            small
            dark
          >
            <v-icon
              class="mr-3"
              medium
              @click.stop=""
            >
              download
            </v-icon>
            Visualizar Laudo Assinado
          </v-btn>
          <v-btn
            v-if="!existsSignedReport() && existsOriginalReport() && !canStatus()"
            class="elevation-0"
            color="primary"
            :loading="loaderbtnUpload"
            small
            dark
            @click.stop="openUploadLaudoAssinado"
          >
            <v-icon
              class="mr-3"
              medium
            >
              upload_file
            </v-icon>
            Upload Laudo Técnico
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <!--/ Table -->

    <!-- FileInput -->
    <v-file-input
      id="uploadLaudoAssinado"
      :value="file"
      label="Laudo Técnico"
      placeholder="Faça o upload do laudo tecnico assinado"
      counter
      show-size
      truncate-length="15"
      color="green"
      outlined
      class=" pa-3 pa-0 pt-5 "
      style="display: none;"
      @change="uploadLaudoAssinado($event)"
    />
    <!--/ FileInput -->
  </v-container>
</template>
<script>
  import { mapState, mapActions } from 'vuex';
  import PedidoService from '@/services/pedidos.service';
  import GlobalStore from '@/store/modules/global';
  import { autovistoria } from '@/store/modules/autovistoria';

  export default {
    name: 'TabLaudoView',
    props: {
      solicitationId: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      rules: {
        required: (value) =>  !!value || 'Esse campo deve ser preenchido' ,
      },
      loaderForm: false,
      file: null,
      iconUpload: 'upload_file',
      headers: [
        {
          text: 'Arquivos',
          align: 'left',
          sortable: false,
          value: 'arquivo',
        },
        { text: 'Ações', value: 'actions' },
      ],
      items: [
        {
          arquivo: 'Laudo Técnico',
          value: 'original_report'
        },
        {
          arquivo: 'Laudo Técnico Assinado',
          value: 'signed_report'
        }
      ],
      loaderbtn: false,
      loaderbtnUpload: false,
    }),
    computed: {
      ...mapState('autovistoria', {
        autovistoria: state => state.detail.data,
        autovistoriaIsLoading: state => state.detail.isLoading
      }),
      user: () => GlobalStore.user || {}
    },
    mounted() {
      this.setEngineerAttributes()
    },
    destroyed() {
      this.setAutovistoria({})
    },
    methods: {
      ...mapActions('autovistoria', {
        findAutovistoria: 'FIND_AUTOVISTORIA',
        setAutovistoria: 'SET_AUTOVISTORIA'
      }),
      setEngineerAttributes() {
        const { name, crea } = this.user
        const { engineer_name, engineer_crea } = this.autovistoria

        if(engineer_name && engineer_crea) return;

        const autovistoria = {...this.autovistoria, engineer_name: name, engineer_crea: crea }
        this.setAutovistoria(autovistoria)
      },
      fetchAutovistoria() {
        this.findAutovistoria({
          solicitationId: this.solicitationId,
          inspectionKey: this.autovistoria.key
        })
      },
      onSubmit() {
        if (this.$refs.form.validate()) {
          this.loaderForm = true;
          const { inspection_at, expiration_at, art, emission_at, engineer_crea, engineer_name } = this.autovistoria

          PedidoService
            .updateAutovistoria(this.solicitationId, this.autovistoria.key, {
              inspection_at,expiration_at, emission_at, art, engineer_crea, engineer_name
            })
            .subscribe(
              res => {
                GlobalStore.setSnackBar({
                  show: true,
                  message: 'Informações salvas com sucesso',
                  type: 'success'
                });
                this.loaderForm = false;
                this.fetchAutovistoria()
              },
              erro => {
                var msg = [JSON.parse(erro.request.responseText)]
                msg.forEach(m => {
                  GlobalStore.setSnackBar({
                    show: true,
                    type: 'error',
                    message: m.errors,
                  });
                })
                this.loaderForm = false;
              }
            )
        }
      },
      uploadLaudoAssinado(event) {
        this.loaderbtnUpload = true
        PedidoService.uploadReport(this.solicitationId,this.autovistoria.key,event)
          .subscribe(
            res => {
              GlobalStore.setSnackBar({
                show: true,
                message: 'Laudo enviado com sucesso',
                type: 'success'
              });
              this.fetchAutovistoria()
              this.loaderbtnUpload = false
            },
            err => {
              GlobalStore.setSnackBar({
                show: true,
                message: 'Não foi possível enviar o laudo ',
                type: 'error'
              });
              this.loaderbtnUpload = false
              this.file = null
            }
          )
      },
      generateLaudo() {
        this.loaderbtn = true
        PedidoService.generateReport(this.solicitationId,this.autovistoria.key)
          .subscribe(
            res => {
              GlobalStore.setSnackBar({
                show: true,
                message: 'Laudo gerado com sucesso',
                type: 'success'
              });
              this.fetchAutovistoria()
              this.loaderbtn = false
            },
            erro => {
              var msg = [JSON.parse(erro.request.responseText)][0].errors[0]
              GlobalStore.setSnackBar({
                show: true,
                type: 'error',
                message: msg,
              });
              this.loaderbtn = false
            }
          )
      },
      openUploadLaudoAssinado() {
        document.getElementById('uploadLaudoAssinado').click()
      },
      existsOriginalReport() {
        return !!this.autovistoria.original_report.content_url
      },
      existsSignedReport() {
        return !!this.autovistoria.signed_report.content_url
      },
      canStatus() {
        const unpermittedStatuses = ['deferred', 'undeferred']

        return unpermittedStatuses.includes(this.autovistoria.status.original_value)
      },
      canDisabled() {
        return this.canStatus() ||!!this.autovistoria.signed_report.content_url
      }
    }
  };
</script>

<style scoped>
.card-download{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border: 1px solid green;
  border-radius: 5%;
  width: 12rem;
  height: 7rem;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
}
.file{
  border: 1px solid green;
}
</style>
