<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      persistent
      max-width="290"
    >
      <v-form
        ref="form"
        @submit.prevent="createAutovistoria()"
      >
        <v-card>
          <v-card-title class="text-subtitle-1">
            ART
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="form.art"
              :rules="[rules.required]"
              name="art"
              filled
              label="Digite o ART"
              auto-grow
              color="success"
              :disabled="isLoading"
            />
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between">
            <v-btn
              color="green darken-1"
              text
              :disabled="isLoading"
              @click="close()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              :disabled="isLoading"
              type="submit"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
  import PedidoService from '@/services/pedidos.service';
  import GlobalStore from '@/store/modules/global';
  import { mapActions } from 'vuex';

  export default {
    name:'DialogArt',
    props:{
      solicitationId: {
        type: String,
        required: true
      }
    },
    data: () =>  ({
      open: true,
      isLoading: false,
      form: {
        art: null
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido'
      }
    }),
    methods:{
      ...mapActions('pedido', { showSolicitation: 'SHOW' }),
      ...mapActions('pedido', { listAutovistorias: 'LIST_AUTOVISTORIAS' }),
      close() {
        this.$emit('close')
        this.form.art = null
        this.$refs.form.reset()
      },
      createAutovistoria(){
        if (this.$refs.form.validate()) {
          this.isLoading = true
          PedidoService
            .createAutovistoria(this.solicitationId,this.build_body())
            .subscribe(res => {
                         this.isLoading = false
                         GlobalStore.setSnackBar({
                           show: true,
                           message: 'Autovistoria criada com sucesso!',
                           type: 'success'
                         });
                         this.listAutovistorias()
                         this.showSolicitation(this.solicitationId)
                         this.close()
                       },
                       erro => {
                         this.isLoading = false
                         var msg = JSON.parse(erro.request.responseText)
                         msg.errors.forEach(m => {
                           GlobalStore.setSnackBar({
                             show: true,
                             type: 'error',
                             message: m,
                           });
                         })
                       })
        }
      },
      build_body() {
        const { art } = this.form
        return {
          'conditions': {
            'kinds': [
              'front_external_vehicle',
              'back_external_vehicle',
              'left_external_vehicle',
              'right_external_vehicle',
              'front_internal_vehicle',
              'back_internal_vehicle',
              'chassi_decal',
              'engine',
              'spare_tire',
              'triangle',
              'cabin',
              'cabin_panel',
              'pedal',
              'extinguisher',
              'front_lighting',
              'back_lighting',
              'cabin_panel_running',
              'left_armchairs',
              'right_armchairs',
            ]
          },
          'art': art
        }
      }
    }
  }
</script>
