<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Motivo da recusa
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="refuse_reason"
            name="input-7-1"
            filled
            label="Digite o motivo da recusa"
            auto-grow
            color="success"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            color="green darken-1"
            text
            @click="saveRefuseReason(false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="saveRefuseReason(true)"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name:'DialogRefudeReason',
    props:{
      dialog: {
        type: Boolean
      }
    },
    data () {
      return {
        refuse_reason: null,
      }
    },
    methods:{
      saveRefuseReason(status){
        this.$emit('saveRefuseReason', {
          'refuse_reason': this.refuse_reason,
          status
        })
        this.refuse_reason = null
      }
    }
  }
</script>
