import { BaseAPI } from './base.api';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';

export class VehicleTypeAPI extends BaseAPI<any> {
  constructor() {
    super();
    this.apiUrl = `${this.apiUrl}/api_dash/v1`;
  }

  public getAll(): AxiosObservable<any> {
    return this.axios.get(`${this.apiUrl}/vehicle_types`)
  }
}
