import { Observable } from 'rxjs';
import { VehicleTypeAPI } from '@/api/vehicle_type.api';

const api = new VehicleTypeAPI();

const getAll = (): Observable<any> => {
  return api.getAll();
}

export default {
  getAll
}
