<template>
  <div>
    <h4 class="title">
      Documentos
    </h4>
    <v-list
      subheader
      two-line
    >
      <v-list-item-group>
        <template v-for="(file, index) in documentos">
          <v-list-item
            :key="index"
            :dense="true"
            :selectable="false"
          >
            <v-list-item-avatar @click.prevent.stop="doDownload(file)">
              <v-icon
                class="red white--text"
                dark
                v-text="'description'"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-if="file.typeDocument"
                class="mt-3"
              >
                {{
                  file.typeDocument ? file.typeDocument.name : "-"
                }}
              </v-list-item-title>

              <v-list-item-subtitle v-text="`Arquivo: ${file.name}`" />

              <v-list-item-subtitle>
                <v-chip
                  v-if="file.status === 'NAO_AVALIADO'"
                  small
                  class="mt-1 mb-1"
                >
                  {{ statusAnexos[file.status] }}
                </v-chip>
                <v-chip
                  v-else
                  small
                  class="mt-1 mb-1"
                  :color="file.status === 'ACEITO' ? 'success' : 'error'"
                >
                  {{ statusAnexos[file.status] }}
                </v-chip>
                <v-chip
                  small
                  class="mt-1 mb-1 ml-3"
                  color="success"
                  @click.prevent.stop="doDownload(file)"
                >
                  <v-icon size="15">
                    file_download
                  </v-icon>Baixar
                </v-chip>
                <v-chip
                  v-if="file.status === 'REJEITADO'"
                  small
                  class="mt-1 mb-1 ml-3"
                  color="warning"
                  @click.prevent.stop="openDialogUpload(file)"
                >
                  <v-icon size="15">
                    edit
                  </v-icon>Ajustar Arquivo
                </v-chip>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="(detail.data.status===`EM_ANALISE` || detail.data.status===`DOCUMENTACAO_PENDENTE` || detail.data.status===`DOCUMENTACAO_PENDENTE_ARCE`) && file.status === 'NAO_AVALIADO'"
              >
                <v-btn-toggle class="mt-2">
                  <v-btn
                    small
                    color="success"
                    class="white--text"
                    title="Aceitar"
                    @click="doAcceptDocument(file)"
                  >
                    <v-icon
                      color="white"
                      size="15"
                    >
                      check_circle
                    </v-icon>Aceitar
                  </v-btn>
                  <v-btn
                    small
                    color="error"
                    class="white--text"
                    title="Recursar"
                    @click="openDialog(file)"
                  >
                    <v-icon
                      color="white"
                      size="15"
                    >
                      cancel
                    </v-icon>Reprovar
                  </v-btn>
                </v-btn-toggle>
              </v-list-item-subtitle>
              <span
                v-if="file.status === 'REJEITADO' && file.reason"
                class="rejeicao-text"
              >
                <strong class="strong">Motivo Rejeição:</strong>
                <p class="text-justify">{{ file.reason }}</p>
              </span>
            </v-list-item-content>
            <v-list-item-content>
              <!-- <v-list-item-title>Informações Complementares</v-list-item-title> -->
              <v-row no-gutters>
                <!-- Cópia CRLV -->
                <v-col
                  v-if="file.typeDocument.key==='COPIA_CRLV'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Renavam:&nbsp;</strong>
                  <span v-if="file.code">{{ file.code }}</span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='COPIA_CRLV'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Emissão:&nbsp;</strong>
                  <span v-if="file.dateOfIssue">
                    {{
                      file.dateOfIssue | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='COPIA_CRLV'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Validade (Licenciamento):&nbsp;</strong>
                  <span v-if="file.dateExpiration">
                    {{
                      file.dateExpiration | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <!--/ Cópia CRLV -->

                <!-- Apólice de Seguro Assinada e Carimbada pela Seguradora -->
                <v-col
                  v-if="file.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Número Apólice:&nbsp;</strong>
                  <span v-if="file.code">{{ file.code }}</span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Seguradora:&nbsp;</strong>
                  <span v-if="file.agencyShipping">
                    {{
                      file.agencyShipping
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Emissão:&nbsp;</strong>
                  <span v-if="file.dateOfIssue">
                    {{
                      file.dateOfIssue | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Validade:&nbsp;</strong>
                  <span v-if="file.dateExpiration">
                    {{
                      file.dateExpiration | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <!--/ Apólice de Seguro Assinada e Carimbada pela Seguradora -->

                <!--  Laudo Técnico -->
                <v-col
                  v-if="file.typeDocument.key==='LAUDO_TECNICO'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Profissional/Empresa Responsável:&nbsp;</strong>
                  <span v-if="file.agencyShipping">
                    {{
                      file.agencyShipping
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='LAUDO_TECNICO'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Emissão:&nbsp;</strong>
                  <span v-if="file.dateOfIssue">
                    {{
                      file.dateOfIssue | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='LAUDO_TECNICO'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Número da ART:&nbsp;</strong>
                  <span v-if="file.code">{{ file.code }}</span>
                  <span v-else>--</span>
                </v-col>
                <!--/  Laudo Técnico -->

                <!-- ART -->
                <v-col
                  v-if="file.typeDocument.key==='ART'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Número da ART:&nbsp;</strong>
                  <span v-if="file.code">{{ file.code }}</span>
                  <span v-else>--</span>
                </v-col>
                <v-col
                  v-if="file.typeDocument.key==='ART'"
                  :lg="6"
                  :md="6"
                >
                  <strong class="strong">Data Emissão:&nbsp;</strong>
                  <span v-if="file.dateOfIssue">
                    {{
                      file.dateOfIssue | date
                    }}
                  </span>
                  <span v-else>--</span>
                </v-col>
                <!--/ ART -->
              </v-row>
            </v-list-item-content>
            <v-list-item-action
              v-if="file.typeDocument.key==='ART' || file.typeDocument.key==='LAUDO_TECNICO' || file.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA' || file.typeDocument.key==='COPIA_CRLV'"
            >
              <v-btn
                v-if="(detail.data.status===`EM_ANALISE` || detail.data.status===`DOCUMENTACAO_PENDENTE` || detail.data.status===`DOCUMENTACAO_PENDENTE_ARCE`) && file.status === 'NAO_AVALIADO'"
                icon
                @click="openDialogInfoComplementar(file)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            :key="file.name + index"
            class="divider-item-list"
          />
        </template>
      </v-list-item-group>
    </v-list>
    <v-dialog
      v-if="dialog.open"
      v-model="dialog.open"
      width="300"
    >
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="doRefuseDocument"
      >
        <v-card class="app-card mb-0">
          <v-card-title class="app-card-title">
            <h4 class="text">
              <span>Recusar Documento</span>
            </h4>
          </v-card-title>

          <v-card-text class="app-card-content">
            <v-textarea
              v-model="formReject.reason"
              label="Motivo *"
              :rules="[rules.required]"
            />
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="error"
              text
              @click.prevent="closeDialog"
            >
              Fechar
            </v-btn>
            <v-btn
              depressed
              color="success"
              type="submit"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-if="dialogComplementar.open"
      v-model="dialogComplementar.open"
      width="800"
    >
      <v-form
        ref="formComplementar"
        lazy-validation
        @submit.prevent="doUpdateInfoDocument"
      >
        <v-card class="app-card mb-0">
          <v-card-title class="app-card-title">
            <h4 class="text">
              <span>Informações Complementares</span>
            </h4>
          </v-card-title>

          <v-card-text class="app-card-content">
            <v-row>
              <!-- Cópia CRLV -->
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='COPIA_CRLV'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.code"
                  label="Renavam"
                  placeholder="Ex:. 313213232"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='COPIA_CRLV'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateOfIssue"
                  v-mask="'##/##/####'"
                  label="Data Emissão"
                  placeholder="Ex:. 13/02/2020"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='COPIA_CRLV'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateExpiration"
                  v-mask="'##/##/####'"
                  label="Data Validade (Licenciamento)"
                  placeholder="Ex:. 13/02/2023"
                  outlined
                />
              </v-col>
              <!-- Cópia CRLV -->

              <!-- Apólice de Seguro Assinada e Carimbada pela Seguradora -->
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.code"
                  label="Número Apólice"
                  placeholder="Ex:. 313213232"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.agencyShipping"
                  label="Seguradora"
                  placeholder="Ex:. Porto Seguro"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateOfIssue"
                  v-mask="'##/##/####'"
                  label="Data Emissão"
                  placeholder="Ex:. 13/02/2020"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateExpiration"
                  v-mask="'##/##/####'"
                  label="Data Validade"
                  placeholder="Ex:. 13/02/2023"
                  outlined
                />
              </v-col>
              <!--/ Apólice de Seguro Assinada e Carimbada pela Seguradora -->

              <!-- Laudo Técnico -->
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='LAUDO_TECNICO'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.agencyShipping"
                  label="Profissional/Empresa Responsável"
                  placeholder="Ex:. Porto Seguro"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='LAUDO_TECNICO'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateOfIssue"
                  v-mask="'##/##/####'"
                  label="Data Emissão"
                  placeholder="Ex:. 13/02/2020"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='LAUDO_TECNICO'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.code"
                  label="Número da ART"
                  placeholder="Ex:. 313213232"
                  outlined
                />
              </v-col>
              <!--/ Laudo Técnico -->

              <!-- ART -->
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='ART'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.code"
                  label="Número da ART"
                  placeholder="Ex:. 313213232"
                  outlined
                />
              </v-col>
              <v-col
                v-if="dialogComplementar.data.typeDocument.key==='ART'"
                :lg="6"
                :md="6"
              >
                <v-text-field
                  v-model="formComplementar.dateOfIssue"
                  v-mask="'##/##/####'"
                  label="Data Emissão"
                  placeholder="Ex:. 13/02/2020"
                  outlined
                />
              </v-col>

              <!--/ ART -->
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <span class="app-form__warning">
              Atenção: Campos que possuem o
              <em>*</em> são obrigatórios
            </span>
            <v-spacer />
            <v-btn
              outlined
              color="error"
              text
              @click.prevent="closeDialogInfoComplementar"
            >
              Fechar
            </v-btn>
            <v-btn
              depressed
              color="success"
              type="submit"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-if="dialogUpload.open"
      v-model="dialogUpload.open"
      width="500"
    >
      <v-form
        ref="form"
        lazy-validation
        class="app-form"
        @submit.prevent="doUploadDocumento"
      >
        <v-card class="mb-0">
          <v-card-title class="app-card-title">
            Upload Documento
          </v-card-title>
          <v-card-text class="app-card-content">
            <!-- <Upload v-model="dialogUpload.file" :nameInput="1" /> -->
            <UploadFile
              v-model="dialogUpload.file"
              :name-input="1"
              @change="doUploadDocumento($event, dialogUpload.documento)"
            />
            <section
              v-if="dialogUpload.isLoading"
              class="loading"
            >
              <v-progress-circular
                indeterminate
                color="success"
              />
              <span>Enviando informações ...</span>
            </section>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              color="error"
              @click="closeDialogUpload"
            >
              Fechar
            </v-btn>
            <!-- <v-btn depressed color="success" type="submit" :disabled="!dialogUpload.file">Enviar</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <DocumentosForm />
  </div>
</template>

<script>
  import { StatusDocumento } from '@/models/status-documento.enum';
  import { mapActions, mapState } from 'vuex';
  import dayjs from 'dayjs';
  import Upload from './Upload';
  import DocumentosForm from './Form';
  import UploadFile from '@/components/UploadFile';
  import { checkDate, checkHour } from '../../../../util/validate';

  export default {
    name: 'DocumentsList',
    components: {
      //Upload,
      UploadFile,
      DocumentosForm
    },
    props: {
      documentos: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      statusAnexos: StatusDocumento,
      dialogUpload: {
        open: false,
        documento: null,
        file: null,
        isLoading: false
      },
      dialog: {
        open: false,
        data: null
      },
      formReject: {
        reason: null
      },
      dialogComplementar: {
        open: false,
        data: null
      },
      formComplementar: {
        dateOfIssue: null,
        dateExpiration: null,
        agencyShipping: null,
        code: null,
        cityId: null,
        surveyHour: null
      },
      rules: {
        required: value => !!value || 'Esse campo deve ser preenchido',
        date: value => !!checkDate(value) || 'Data inválida',
        hour: value => !!checkHour(value) || 'Hora inválida'
      }
    }),
    computed: {
      ...mapState('pedido', ['form']),
      ...mapState('pedido', ['detail'])
    },
    methods: {

      openDialogInfoComplementar(item) {
        this.dialogComplementar = {
          ...this.dialogComplementar,
          open: true,
          data: item
        };

        const {
          dateOfIssue,
          dateExpiration,
          agencyShipping,
          code,
          cityId,
          surveyHour
        } = item;
        dayjs(dateOfIssue, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.formComplementar = {
          dateOfIssue: dateOfIssue
            ? dayjs(dateOfIssue, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          dateExpiration: dateExpiration
            ? dayjs(dateExpiration, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : null,
          agencyShipping,
          code,
          cityId,
          surveyHour
        };
      },
      closeDialogInfoComplementar() {
        this.dialogComplementar = {
          ...this.dialogComplementar,
          open: false,
          data: null
        };
        this.formComplementar = {
          ...this.formComplementar,
          dateOfIssue: null,
          dateExpiration: null,
          agencyShipping: null,
          code: null,
          cityId: null,
          surveyHour: null
        };
      },
      openDialog(item) {
        this.dialog = { ...this.dialog, open: true, data: item };
      },
      closeDialog() {
        this.dialog = { ...this.dialog, open: false, data: null };
        this.formReject = { ...this.formReject, reason: null };
      },
      doAcceptDocument(item) {
        const body = { ...item, status: 'ACEITO' };
        this.updateStatusDocumento(body);
      },
      async doRefuseDocument() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          const { reason } = this.formReject;
          const { id } = this.dialog.data;
          await this.updateStatusDocumento({
            id,
            status: 'REJEITADO',
            reason
          });
          this.closeDialog();
        }
      },
      async doUpdateInfoDocument() {
        const isValid = this.$refs.formComplementar.validate();

        if (isValid) {
          const pedido = {
            id: this.dialogComplementar.data.id,
            ...this.formComplementar
          };
          await this.updateInfosDocumento(pedido);
          this.closeDialogInfoComplementar();
        }
      },
      openDialogUpload(item) {
        this.dialogUpload.open = true;
        this.$store.commit('pedido/SET_DOCUMENT_FORM', {
          typeDocumentKey: item.typeDocument.key,
          doc: {
            ...item,
            filename: null,
            type: null,
            size: 0,
            progress: 0,
            uploaded: false,
            error: false
          }
        });
        //      this.dialogUpload.documento = item;
        this.dialogUpload.documento = this.form.documents.find(
          d => d.typeDocumentKey === item.typeDocument.key
        );
      },
      closeDialogUpload() {
        this.dialogUpload = {
          open: false,
          documento: null,
          file: null,
          isLoading: false
        };
        this.getPedido(this.detail.data.id);
      },
      async doUploadDocumento(event, doc) {
        this.dialogUpload.isLoading = true;
        const documento = {
          currentDocument: this.dialogUpload.documento,
          file: event.target.files[0]
        };
        await this.uploadDocument(documento);
        this.closeDialogUpload();
      },
      doDownload(item) {
        this.downloadDocumento(item);
      },
      ...mapActions('pedido', {
        getPedido: 'SHOW'
      }),
      ...mapActions('pedido', {
        downloadDocumento: 'DOWNLOAD_DOCUMENTO'
      }),
      ...mapActions('pedido', {
        uploadDocument: 'uploadDocument'
      }),
      ...mapActions('pedido', {
        updateStatusDocumento: 'UPDATE_DOCUMENTO_STATUS'
      }),
      ...mapActions('pedido', {
        updateInfosDocumento: 'UPDATE_DOCUMENTO_INFOS'
      })
    },
  };
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold !important;
  border-bottom: solid 1px #c5c5c5;
  margin-bottom: 20px;
  margin-top: 0px;
  padding-bottom: 5px;
}
.divider-item-list {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.rejeicao-text {
  display: block;
}
</style>
