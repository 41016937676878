/* eslint-disable */
<template>
  <section>
    <h3 class="mt-4 mb-1">
      Informações da Transportadora
    </h3>
    <v-divider class="mb-8" />
    <v-form
      ref="form"
      lazy-validation
      class="app-form mt-4"
      @submit.prevent="onSubmitCompanyForm"
    >
      <v-row>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.code"
            label="Código da Transportadora *"
            placeholder="Digite o código da empresa"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.email"
            label="Email *"
            placeholder="Digite o email da empresa"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required, rules.email]"
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.cell_phone"
            v-mask="['(##) #####-####']"
            label="Telefone *"
            placeholder="Digite o telefone da empresa"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
        <v-divider class="mb-5" />
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.zipcode"
            v-mask="['#####-###']"
            label="CEP *"
            placeholder="Digite o CEP*"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
            :loading="loader"
            :messages="msg"
            @keyup="handlerInputCep()"
          />
        </v-col>
        <v-col
          md="6"
          sm="12"
          xs="12"
          lg="6"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.address"
            label="Rua *"
            placeholder="Digite o nome da rua*"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          md="2"
          sm="12"
          xs="12"
          lg="2"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.number"
            label="Número *"
            placeholder="Digite o número*"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.complement"
            label="Complemento"
            placeholder="Digite o complemento"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.district"
            label="Bairro *"
            placeholder="Digite o bairro*"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col
          md="4"
          sm="12"
          xs="12"
          lg="4"
          class="pt-0"
        >
          <v-text-field
            v-model="empresaForm.address.city"
            label="Cidade *"
            placeholder="Digite a cidade*"
            outlined
            dense
            :disabled="autovistorias.desabiliteFields"
            color="success"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="success"
          depressed
          type="submit"
        >
          Salvar
        </v-btn>
      </v-card-actions>
      <section
        v-if="loaderForm"
        class="loading"
      >
        <v-progress-circular
          indeterminate
          color="success"
        />
        <span>Carregando informações...</span>
      </section>
    </v-form>
    <h3 class="mt-5 mb-1">
      Autovistorias
    </h3>
    <v-divider class="mb-4 " />
    <v-btn
      v-if="!isEngineer()"
      color="success"
      depressed
      outlined
      type="buttom"
      class="mb-4"
      @click="openDialogART()"
    >
      Criar Autovistoria
    </v-btn>
    <DialogART
      v-if="openArtDialog"
      :solicitation-id="detail.data.id"
      @close="closeDialogART"
    />
    <v-data-table
      :headers="headers"
      :items="autovistorias"
      :loading="autovistoriaIsLoading"
      :hide-default-footer="true"
      class="elevation-1 mt-7"
      @click:row="openDialogAutovistoria"
    >
      <template v-slot:item.actions="{item}">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                class="ml-3"
                medium
                :disabled="!canResendAutovistoria(item)"
                @click.stop="resendAutovistoria(item)"
              >
                forward_to_inbox
              </v-icon>
            </span>
          </template>
          <span>Reenviar autovistoria</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                class="ml-3"
                medium
                :disabled="!canSendSMS(item)"
                @click.stop="sendSMS(item)"
              >
                sms
              </v-icon>
            </span>
          </template>
          <span>Enviar pendência</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                :href="item.signed_report.content_url"
                target="_blank"
                icon
                :disabled="!canDownloadSignedReport(item)"
              >
                <v-icon
                  medium
                >
                  download
                </v-icon>
              </v-btn>
            </span>
          </template>
          <span>Visualizar laudo assinado</span>
        </v-tooltip>
      </template>
      <template v-slot:item.inspection_at="{item}">
        {{ formatDate(item.inspection_at) }}
      </template>
      <template v-slot:item.expiration_at="{item}">
        {{ formatDate(item.expiration_at) }}
      </template>
      <template v-slot:item.emission_at="{item}">
        {{ formatDate(item.emission_at) }}
      </template>
    </v-data-table>
    <DetailsAutovistoria
      v-if="dialogAutovistoria.open"
      :inspection-key="dialogAutovistoria.inspectionKey"
      :solicitation-id="dialogAutovistoria.solicitationId"
      @closeDialog="closeDialogAutovistoria"
    />
  </section>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import EmpresaService from '@/services/empresa.service';
  import CepService from '@/services/cep.service';
  import GlobalStore from '@/store/modules/global';
  import PedidoService from '@/services/pedidos.service';
  import DetailsAutovistoria from '../components/DetailsAutovistoria.vue'
  import DialogART from './DialogART.vue';

  import dayjs from 'dayjs';
  import { NoUnusedVariablesRule } from 'graphql';

  export default {
    name: 'EmpresaForm',
    components:{
      DetailsAutovistoria,
      DialogART
    },
    props: {
      company: {
        type: Object,
        required: true
      }
    },
    data: () => ({
      empresaForm: {
        address: {
          address: null,
          district: null,
          city: null,
          state: null,
          zipcode: null
        },
        email: null,
        cell_phone: null,
        address_attributes: {},
        code: null
      },
      rules: {
        required: (value) => !!value || 'Esse campo deve ser preenchido',
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        },
      },
      loader: false,
      loaderForm: false,
      msg: '',
      cep: '',
      headers: [
        {
          text: 'Chave',
          align: 'left',
          sortable: false,
          value: 'key',
        },
        {
          text: 'Data Inspeção',
          align: 'center',
          sortable: false,
          value: 'inspection_at',
        },
        {
          text: 'Data Expiração',
          align: 'center',
          sortable: false,
          value: 'expiration_at',
        },
        {
          text: 'Data Emissão',
          align: 'center',
          sortable: false,
          value: 'emission_at',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: false,
          value: 'status.translated_value',
        },
        {
          text: 'Ações',
          align: 'left',
          sortable: false,
          value: 'actions',
        },
      ],
      dialog: false,
      dialogAutovistoria: {
        open: false,
        solicitationId: null,
        inspectionKey: null
      },
      detailAutovistoria:{},
      desabilite: false,
      permissions: [{
        btn:'create',
        privilege: 'inspection_review'
      }],
      openArtDialog: false
    }),
    computed: {
      ...mapState('global', ['user']),
      ...mapState('pedido', {
        detail: state => state.detail,
        autovistorias: state => state.autovistoria.table,
        autovistoriaIsLoading: state => state.autovistoria.tableIsLoading
      })
    },
    mounted(){
      this.getCompanyDetails()
    },
    methods:{
      ...mapActions('pedido', { showSolicitation: 'SHOW' }),
      ...mapActions('pedido', { listAutovistorias: 'LIST_AUTOVISTORIAS' }),
      getCompanyDetails() {
        this.loaderForm = true
        EmpresaService.getCompany(this.company.id)
          .subscribe(
            ({ email, cell_phone, code, address }) =>{
              const address_normalize = address ? address : this.empresaForm.address

              this.empresaForm = {
                ...this.empresaForm,
                email,
                cell_phone,
                code,
                address: address_normalize
              }
              this.loaderForm = false
              this.listAutovistorias()
            },
            erro =>
            {
              this.loaderForm = false
            }
          )
      },
      onSubmitCompanyForm(){
        this.empresaForm.cell_phone = this.empresaForm.cell_phone.replace('-','')
        const idEmpresa = this.company.id

        const body = {
          email:  this.empresaForm.email,
          cell_phone:  this.empresaForm.cell_phone,
          code: this.empresaForm.code,
          address_attributes:  this.empresaForm.address
        }

        EmpresaService.updateCompany(idEmpresa,body).subscribe(
          res => {
            this.empresaForm.email = res.email;
            this.empresaForm.cell_phone = res.cell_phone;
            this.empresaForm.address = res.address
            this.empresaForm.code = res.code

            GlobalStore.setSnackBar({
              show: true,
              message: 'Informações atualizadas com sucesso',
              type: 'success'
            });
          },
          err => {
            GlobalStore.setSnackBar({
              show: true,
              message: 'Não foi possível atualizar as informações ',
              type: 'error'
            });
          })
      },
      handlerInputCep() {
        if (this.empresaForm.address.zipcode.length === 9) {
          this.loader = true;
          this.msg = 'Pesquisando';
          this.searchCEP();
        }
      },
      async searchCEP() {
        try {
          const { cep, city, neighborhood, service, state, street } =
            await CepService.searchCEP(this.empresaForm.address.zipcode).toPromise();
          this.loader = false;
          this.msg = 'Endereço encontrado';
          this.empresaForm.address.address = street;
          this.empresaForm.address.district = neighborhood;
          this.empresaForm.address.city = city;
          this.empresaForm.address.state = state;
          this.empresaForm.address.zipcode = cep;

        } catch (err) {
          this.loader = false;
          this.msg = '';
        }
      },
      closeDialogAutovistoria() {
        this.dialogAutovistoria = {
          open: false,
          solicitationId: null,
          inspectionKey: null
        }

        this.listAutovistorias()
      },
      openDialogAutovistoria(data) {
        const { id } = this.detail.data;
        const { key } = data;

        this.dialogAutovistoria = {
          ...this.dialogAutovistoria,
          open: true,
          solicitationId: id,
          inspectionKey: key
        }
      },

      isEngineer() {
        const { functionalities } = this.user;
        return functionalities.includes('inspection_review')
      },
      formatDate(date) {
        return dayjs(date).isValid() ? dayjs(date).format('DD/MM/YYYY') : '-';
      },
      canResendAutovistoria(item) {
        return !this.isEngineer() && item.status.original_value == 'awaiting_evidences'
      },
      resendAutovistoria(item) {
        const { id } = this.detail.data;
        const { key } = item;
        PedidoService.resendAutovistoria(id, key)
          .subscribe(res => {
                       GlobalStore.setSnackBar({
                         show: true,
                         message: 'Email enviado com sucesso!',
                         type: 'success'
                       });
                     }
                     ,err => {
                       GlobalStore.setSnackBar({
                         show: true,
                         type: 'error',
                         message: 'Não possível reenviar o email',
                       });
                     })
      },
      sendSMS(item) {
        const { id } = this.detail.data;
        const { key } = item;
        PedidoService.sendEvidenceRefusend(id, key)
          .subscribe(res => {
                       GlobalStore.setSnackBar({
                         show: true,
                         message: 'SMS enviado com sucesso!',
                         type: 'success'
                       });
                     }
                     ,err => {
                       GlobalStore.setSnackBar({
                         show: true,
                         type: 'error',
                         message: 'Não possível reenviar o SMS',
                       });
                     })
      },
      canSendSMS(item) {
        const { requirements, status:{ original_value } } = item;
        const statusRequirements = requirements.map(e => ([
          e.status.original_value
        ])).flat()
        return statusRequirements.includes('refused') && original_value == 'awaiting_evidences' && !this.isEngineer()
      },
      canDownloadSignedReport(item) {
        return item.signed_report.content_url && item.status.original_value != 'undeferred'
      },
      closeDialogART(){
        this.openArtDialog = false

      },
      openDialogART(){
        this.openArtDialog = true
      },
    },
  };
</script>
<style scoped>
.divider-form {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  border-color: rgb(219, 219, 219);
}
</style>
