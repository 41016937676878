<template>
  <v-row
    v-if="form.documents.length !== detail.data.documents.length"
    class="mt-4"
  >
    <v-col
      :lg="12"
      :sm="12"
      :md="12"
    >
      <h4 class="app-stepper-title">
        Faça o upload dos arquivos
      </h4>
    </v-col>
    <v-col
      v-for="(doc, index) in form.documents"
      :key="doc.typeDocumentKey"
      :lg="12"
      :sm="12"
      :md="12"
    >
      <h4 class="title">
        {{ doc.typeDocumentName }}
      </h4>
      <p>{{ doc.typeDocumentDescription }}</p>
      <UploadFile
        v-model="doc.file"
        :name-input="index"
        @change="onUpload($event, doc)"
      />
      <v-list
        v-if="doc.file"
        subheader
        two-line
      >
        <v-list-item
          :key="index"
          :dense="true"
          :selectable="true"
        >
          <v-list-item-avatar>
            <v-icon
              class="red darken-2"
              dark
              style="color:#fff !important;"
            >
              picture_as_pdf
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="doc.filename" />

            <v-list-item-subtitle
              v-text="`Tipo: ${doc.type} Tamanho: ${fileSize(doc.size)}`"
            />

            <!-- <v-list-item-subtitle>
              <v-btn
                type="button"
                @click="remove(doc)"
                title="Remover arquivo"
                text
                color="red"
                small
              >
                Excluir
              </v-btn>
            </v-list-item-subtitle> -->
          </v-list-item-content>
          <v-list-item-action>
            <v-icon
              v-if="doc.uploaded"
              size="24"
              color="#78e5d5"
            >
              check_circle
            </v-icon>
            <v-icon
              v-if="doc.error"
              size="24"
              color="#e57878"
            >
              info
            </v-icon>
            <v-progress-circular
              v-if="!doc.error && !doc.uploaded"
              size="21"
              :width="3"
              :value="doc.progress"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider class="mt-6" />
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import UploadFile from '@/components/UploadFile';
  import { fileSize } from '@/util/format-file';

  export default {
    name: 'PedidoDocumentosForm',
    components: {
      UploadFile
    },
    data: () => ({
      documentPagination: {
        input: {
          name: null,
          typeSolicitationId: null
        },
        inputPage: {
          page: 1,
          size: 20,
          order: 'name',
          descingOrder: false
        }
      }
    }),
    computed: {
      ...mapState('pedido', ['detail']),
      ...mapState('pedido', ['form']),
      hasGoNextStep: function() {
        return this.form.documents.every(d => d.uploaded && !d.error);
      },
      currentSolicitationType: function() {
        return this.detail.data ? this.detail.data.typeSolicitation.id : null;
      }
    },
    watch: {
      currentSolicitationType: function(newValue, oldValue) {
        if (newValue) {
          this.documentPagination.input.typeSolicitationId = newValue;
          this.findAllTypeDocuments();
        }
      },
      hasGoNextStep: function(newValue, oldValue) {
        if (newValue) {
          this.getPedido(this.detail.data.id);
        }
      }
    },
    mounted() {
      this.findAllTypeDocuments();
    },
    methods: {
      fileSize(v) {
        return fileSize(v);
      },
      onUpload(event, doc) {
        this.uploadDocument({
          currentDocument: doc,
          file: event.target.files[0]
        });
      },
      findAllTypeDocuments() {
        this.documentPagination.input.typeSolicitationId = Number(
          this.detail.data.typeSolicitation.id
        );
        this.pageTipoDocumento(this.documentPagination);
      },
      ...mapActions('pedido', {
        pageTipoDocumento: 'findTypesDocuments'
      }),
      ...mapActions('pedido', {
        uploadDocument: 'uploadDocument'
      }),
      ...mapActions('pedido', { getPedido: 'SHOW' })
    },
  };
</script>
