<template>
  <section>
    <v-card-text
      v-if="autovistoria"
      class="text-subtitle-1 text-left mt-0 pa-0"
    >
      <v-row
        class=" justify-center align-start"
        no-gutters
        style="flex-wrap: nowrap;"
      >
        <v-list
          id="scroll-target"
          style=" max-height: 50rem; overflow-x: hidden"
          class="overflow-y-auto"
        >
          <v-list-item-group
            v-model="selectedItem"
            color="grey darken-2"
          >
            <v-list-item
              v-for="(requirements, index) in autovistoria.requirements"
              :id="'evidence' + index"
              :key="index"
              color="grey darken-2"
            >
              <div
                class="list-evidences pa-5"
                cols="6"
                @click="selectEvidence(requirements)"
              >
                <div class="d-flex">
                  <v-icon
                    class="red white--text rounded-circle ma-3 icon-description"
                    dark
                    v-text="'image'"
                  />
                  <div>
                    <p
                      class="text-subtitle-1 font-weight-medium mb-1 pa-0"
                    >
                      {{ requirements.kind.name }}
                    </p>
                    <div class="d-flex">
                      <v-chip
                        small
                        class="subtitle-2 font-weight-regular"
                        outlined
                        :color="filterColor(requirements.status.original_value)"
                      >
                        {{ requirements.status.translated_value }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider />
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-col class="d-flex ma-6 align-start justify-center">
          <div
            v-if="Object.keys(evidenceSelected).length > 0"
            class="d-flex flex-column justify-center align-center"
          >
            <h4 class="mb-1">
              {{ evidenceSelected.kind.name }}
            </h4>
            <div
              v-if="loadingImg"
              class="d-flex flex-column justify-center align-center rounded"
              style="height: 400px; width: 450px"
            >
              <v-progress-circular
                indeterminate
                color="green"
              />
              <p>Buscando arquivo</p>
            </div>
            <div v-if="!loadingImg">
              <div v-if="evidenceSelected.content_url">
                <img
                  v-if="evidenceSelected.kind.content_type == 'image'"
                  :src="evidenceSelected.content_url"
                  :alt="evidenceSelected.kind.code"
                  width="70%"
                  class="rounded"
                  style="display: block; margin: auto;"
                >
                <video
                  v-if="evidenceSelected.kind.content_type == 'video'"
                  :src="evidenceSelected.content_url"
                  class="rounded"
                  controls
                  width="100%"
                  style="height: 800px; display: block; margin: auto;"
                />
                <p
                  v-if="evidenceSelected.status.original_value == 'refused'"
                  class="text-subtitle-2 mt-3"
                >
                  Motivo da recusa: {{ evidenceSelected.refuse_reason }}
                </p>
              </div>
              <p
                v-else
              >
                Nenhuma arquivo encontrado
              </p>
            </div>
            <div
              v-if="canApproveOrReject()"
              class="d-flex mt-3"
            >
              <v-btn
                small
                color="success"
                class="white--text mr-2"
                title="Aceitar"
                :loading="approveBtn"
                :disabled="
                  evidenceSelected.status.original_value == 'unverified'
                    ? false
                    : true
                "
                @click="approvedOrRejectImages('done', evidenceSelected.key)"
              >
                <v-icon
                  color="white"
                  size="15"
                >
                  check_circle
                </v-icon>Aceitar
              </v-btn>
              <v-btn
                small
                color="error"
                class="white--text"
                title="Recursar"
                :loading="reprovBtn"
                :disabled="
                  evidenceSelected.status.original_value == 'unverified'
                    ? false
                    : true
                "
                @click="openDialogRefuse()"
              >
                <v-icon
                  color="white"
                  size="15"
                >
                  cancel
                </v-icon>
                Reprovar
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <DialogRefudeReason
      :dialog="openDialogRefuseReason"
      @saveRefuseReason="saveRefuseReason"
    />
  </section>
</template>
<script>
  import PedidoService from '@/services/pedidos.service';
  import GlobalStore from '@/store/modules/global';
  import DialogRefudeReason from './DialogRefuseReason.vue';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'TabEvidences',
    components: {
      DialogRefudeReason,
    },
    props: {
      solicitationId: {
        type: String,
        require: true,
        default: null
      },
    },
    data: () => ({
      evidenceSelected: {} ,
      selectedItem: 0,
      loadingImg: true,
      temp: true,
      openDialogRefuseReason:false,
      approveBtn:false,
      reprovBtn:false,

    }),
    computed: {
      ...mapState('global', ['user']),
      ...mapState('autovistoria', {
        autovistoria: state => state.detail.data,
        autovistoriaIsLoading: state => state.detail.isLoading
      })
    },
    watch:{
      evidenceSelected: function(newValue,oldValue){
        this.evidenceSelected = newValue
      }
    },
    mounted(){
      if (Object.keys(this.evidenceSelected).length == 0) {
        this.evidenceSelected = this.autovistoria.requirements[0]
        setTimeout(() => {
          this.loadingImg = false
        }, '900');
      }
    },
    methods: {
      ...mapActions('pedido', { findPedido: 'SHOW' }),
      ...mapActions('autovistoria', { findAutovistoria: 'FIND_AUTOVISTORIA' }),
      fetchPedido() {
        this.findPedido(this.solicitationId)
      },
      fetchAutovistoria() {
        this.findAutovistoria({
          solicitationId: this.solicitationId,
          inspectionKey: this.autovistoria.key
        })
      },
      selectEvidence(data) {
        this.loadingImg = true;
        this.evidenceSelected = data;
        setTimeout(() => {
          this.loadingImg = false;
        }, '900');
      },
      filterColor(status) {
        const arrayColors = [
          { status: 'done', color: 'success' },
          { status: 'refused', color: 'error' },
          { status: 'unverified', color: 'gray' },
          { status: 'pending', color: 'orange' }
        ]
        const selectColor = arrayColors.filter(color => color.status == status)
        return selectColor[0].color || ''
      },
      approvedOrRejectImages(status, key, refuse_reason) {
        status == 'refused' ? this.reprovBtn = true : this.approveBtn = true
        PedidoService.updateApproveImages(this.solicitationId, this.autovistoria.key, key, { status, refuse_reason })
          .subscribe(
            res => {
              this.selectEvidence(this.autovistoria.requirements[this.selectedItem])
              this.evidenceSelected = res
              this.fetchPedido()
              this.fetchAutovistoria()
              status == 'refused' ? this.reprovBtn = false : this.approveBtn = false
            },
            erro => {
              status == 'refused' ? this.reprovBtn = false : this.approveBtn = false
              var msg = JSON.parse(erro.request.responseText)
              msg.errors.forEach(m => {
                GlobalStore.setSnackBar({
                  show: true,
                  type: 'error',
                  message: m,
                });
              })
            }
          )
      },
      saveRefuseReason(val){
        if(val.status){
          this.approvedOrRejectImages('refused', this.evidenceSelected.key, val.refuse_reason)
          this.openDialogRefuseReason = false
        }
        this.openDialogRefuseReason = false
      },
      openDialogRefuse(){
        this.openDialogRefuseReason = true
      },
      isEngineer() {
        const { functionalities } = this.user;
        return functionalities.includes('inspection_review');
      },
      canApproveOrReject() {
        return ['making_operator_analise'].includes(this.autovistoria.status.original_value) &&
          this.evidenceSelected.status.original_value == 'unverified' &&
          !this.isEngineer()
      }
    },
  };
</script>
<style lang="scss" scoped>
.icon-description {
  width: 3rem;
  height: 3rem;
}

.list-evidences {
  cursor: pointer;
  width: 35rem;
}
</style>
