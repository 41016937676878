<template>
  <v-row justify="center">
    <v-dialog
      v-model="openDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeDialog"
    >
      <v-card class="p-0 m-0 elevation-0">
        <!-- Header -->
        <v-card-title style="background: #067B26; border-radius: 0px;">
          <section>
            <v-chip
              v-if="!autovistoriaIsLoading"
              color="white"
              outlined
              class="mr-8"
            >
              <p class="pa-0 ma-0 text-uppercase">
                PEDIDO: {{ solicitationId }}
              </p>
            </v-chip>
            <v-skeleton-loader
              v-else
              class="mx-auto mr-8"
              max-width="300"
              type="chip"
            />
          </section>
          <section>
            <v-chip
              v-if="!autovistoriaIsLoading"
              color="white"
              outlined
              class="mr-8"
            >
              <p class="pa-0 ma-0 text-uppercase">
                PLACA: {{ autovistoria ? autovistoria.vehicle.plate : '-' }}
              </p>
            </v-chip>
            <v-skeleton-loader
              v-else
              class="mx-auto mr-8"
              max-width="300"
              type="chip"
            />
          </section>
          <section>
            <v-chip
              v-if="!autovistoriaIsLoading"
              color="white"
              outlined
            >
              <p class="pa-0 ma-0 text-uppercase">
                STATUS: {{ autovistoria ? autovistoria.status.translated_value: '-' }}
              </p>
            </v-chip>
            <v-skeleton-loader
              v-else
              class="mx-auto mr-8"
              max-width="300"
              type="chip"
            />
          </section>
          <v-spacer />
          <section v-if="autovistoria">
            <v-btn
              v-if="canAnalyseEngineer()"
              class="mr-3"
              color="primary"
              depressed
              :disabled="autovistoriaIsLoading"
              @click="analisarAutovistoria('making_engineer_analise')"
            >
              <v-icon>visibility</v-icon>  Analisar
            </v-btn>
            <v-btn
              v-if="canAnalyseOperator()"
              class="mr-3"
              color="primary"
              depressed
              :disabled="autovistoriaIsLoading"
              @click="analisarAutovistoria('making_operator_analise')"
            >
              <v-icon>visibility</v-icon> Analisar
            </v-btn>
            <v-btn
              v-if="canAccept()"
              class="mr-3 green"
              dark
              depressed
              :disabled="autovistoriaIsLoading"
              @click="deferirAutovistoria()"
            >
              <v-icon>check</v-icon> Deferir
            </v-btn>
            <v-btn
              v-if="canReject()"
              class="mr-3 red"
              dark
              depressed
              :disabled="autovistoriaIsLoading"
              @click="openDialogRefuse()"
            >
              <v-icon>close</v-icon> Indeferir
            </v-btn>
          </section>
          <v-btn
            text
            color="white"
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <!--/ Header -->

        <!-- Content -->
        <v-card-text class="app-card-content pt-5">
          <!-- Refused -->
          <h3
            v-if="autovistoria && autovistoria.reject_reason"
            class="mb-4"
          >
            Motivo do indeferimento: {{ autovistoria.reject_reason }}
          </h3>
          <!--/ Refused -->

          <!-- Tabs -->
          <section
            v-if="autovistoria"
            class="area-tabs"
          >
            <v-tabs
              v-model="currentTab"
              color="#067B26"
            >
              <v-tab>
                <v-icon class="mr-2">
                  plagiarism
                </v-icon> Evidências
              </v-tab>
              <v-tab v-if="canViewTabs()">
                <v-icon class="mr-2">
                  directions_car
                </v-icon>Veículo
              </v-tab>
              <v-tab v-if="canViewTabs()">
                <v-icon class="mr-2">
                  checklist
                </v-icon>Checklist
              </v-tab>
              <v-tab v-if="canViewTabs()">
                <v-icon class="mr-2">
                  article
                </v-icon>Laudo
              </v-tab>
              <v-tabs-items
                v-model="currentTab"
              >
                <v-tab-item>
                  <TabEvidencesVue :solicitation-id="solicitationId" />
                </v-tab-item>
                <v-tab-item>
                  <TabVehicleVue :solicitation-id="solicitationId" />
                </v-tab-item>
                <v-tab-item>
                  <TabChecklistVue :solicitation-id="solicitationId" />
                </v-tab-item>
                <v-tab-item>
                  <TabLaudoVue :solicitation-id="solicitationId" />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </section>
        <!--/ Tabs -->
        </v-card-text>
      <!--/ Content -->
      </v-card>

      <!-- Refuse Dialog -->
      <EngineerRefuseReasonVue
        v-if="openDialogRefuseReason"
        @saveRefuseReason="saveRefuseReason"
      />
    <!--/ Refuse Dialog -->
    </v-dialog>
  </v-row>
</template>
<script>
  import TabChecklistVue from './TabChecklist.vue';
  import TabEvidencesVue from './TabEvidences.vue';
  import TabVehicleVue from './TabVehicle.vue';
  import TabLaudoVue from './TabLaudo.vue';
  import PedidoService from '@/services/pedidos.service';
  import EngineerRefuseReasonVue from './EngineerRefuseReason.vue';
  import GlobalStore from '@/store/modules/global';
  import { mapState, mapActions } from 'vuex';
  import { autovistoria } from '@/store/modules/autovistoria';

  export default {
    name: 'DetailsAutovistoria',
    components: {
      TabEvidencesVue,
      TabVehicleVue,
      TabChecklistVue,
      TabLaudoVue,
      EngineerRefuseReasonVue
    },
    props: {
      inspectionKey: {
        type: String,
        require: true,
        default: null
      },
      solicitationId: {
        type: String,
        require: true,
        default: null
      },
    },
    data: () => ({
      openDialog: true,
      openDialogRefuseReason: false,
      currentTab: null,
    }),
    computed: {
      ...mapState('global', ['user']),
      ...mapState('autovistoria', {
        autovistoria: state => state.detail.data,
        autovistoriaIsLoading: state => state.detail.isLoading
      })
    },
    created() {
      this.fetchAutovistoria()
    },
    methods: {
      ...mapActions('autovistoria', {
        findAutovistoria: 'FIND_AUTOVISTORIA',
        clearAutovistoria: 'CLEAR_DETAIL'
      }),
      ...mapActions('pedido', { showSolicitation: 'SHOW' }),
      fetchAutovistoria() {
        this.findAutovistoria({
          solicitationId: this.solicitationId,
          inspectionKey: this.inspectionKey
        })
      },
      closeDialog() {
        this.$emit('closeDialog', false);
        this.clearAutovistoria();
      },
      deferirAutovistoria() {
        PedidoService.deferAutovistoria(
          this.solicitationId,
          this.inspectionKey
        ).subscribe(
          (res) => {
            GlobalStore.setSnackBar({
              show: true,
              message: 'Autovistoria Deferida!',
              type: 'success',
            });
            this.showSolicitation(this.solicitationId)
            this.fetchAutovistoria()
          },
          (erro) => {
            var msg = [JSON.parse(erro.request.responseText)];
            msg.forEach((m) => {
              GlobalStore.setSnackBar({
                show: true,
                type: 'error',
                message: m.errors,
              });
            });
          }
        );
      },
      indeferirAutovistoria(rejectReason) {
        PedidoService.denyAutovistoria(
          this.solicitationId,
          this.inspectionKey,
          rejectReason
        ).subscribe(
          (res) => {
            GlobalStore.setSnackBar({
              show: true,
              message: 'Autovistoria Indeferida!',
              type: 'success',
            });
            this.showSolicitation(this.solicitationId)
            this.fetchAutovistoria()
          },
          (erro) => {
            var msg = [JSON.parse(erro.request.responseText)];
            msg.forEach((m) => {
              GlobalStore.setSnackBar({
                show: true,
                type: 'error',
                message: m.errors,
              });
            });
          }
        );
      },
      analisarAutovistoria(status) {
        PedidoService
          .updateAutovistoria(this.solicitationId, this.autovistoria.key, { status })
          .subscribe((response) => {
            this.showSolicitation(this.solicitationId)
            this.fetchAutovistoria()
          }, (error) => {
            var msg = [JSON.parse(error.request.responseText)];
            msg.forEach((m) => {
              GlobalStore.setSnackBar({
                show: true,
                type: 'error',
                message: m.errors,
              });
            });
          })
      },
      verifyIsEngineer() {
        const { functionalities } = this.user;
        return functionalities.includes('inspection_review');
      },
      canAccept() {
        return (
          this.autovistoria.signed_report.content_url != null &&
          this.verifyIsEngineer() &&
          this.autovistoria.status.original_value == 'making_engineer_analise'
        );
      },
      canReject() {
        return (
          this.verifyIsEngineer() &&
          this.autovistoria.status.original_value == 'making_engineer_analise'
        );
      },
      canAnalyseEngineer() {
        return this.verifyIsEngineer() &&
          this.autovistoria.status.original_value == 'awaiting_engineer_analise'
      },
      canAnalyseOperator() {
        return !this.verifyIsEngineer() &&
          this.autovistoria.status.original_value == 'awaiting_operator_analise'
      },
      canViewTabs() {
        const permitedStatuses = ['making_engineer_analise', 'deferred', 'undeferred']

        return this.verifyIsEngineer() &&
          permitedStatuses.includes(this.autovistoria.status.original_value)
      },
      saveRefuseReason(val) {
        const { reject_reason, status } = val;
        if (status) {
          this.indeferirAutovistoria(reject_reason);
          this.openDialogRefuseReason = false;
        }
        this.openDialogRefuseReason = false;
      },
      openDialogRefuse() {
        this.openDialogRefuseReason = true;
      }
    },
  };
</script>

<style type="scss">
.area-loading{
  text-align: center;
}
</style>
