<template>
  <section>
    <div
      class="p-12 bg-gray-100 border border-gray-300"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        :id="`assetsFieldHandle${nameInput}`"
        ref="file"
        type="file"
        :name="`fields[assetsFieldHandle${nameInput}][]`"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        accept="application/pdf"
        @change="onChange($event)"
      >

      <label
        :for="`assetsFieldHandle${nameInput}`"
        class="block cursor-pointer"
      >
        <div>
          Arraste e solte seu arquivo ou
          <span class="underline">clique aqui</span> para fazer o upload.
          <br>
          <span
            class="extension"
          >Atenção: Só é aceito arquivos com extensão ".pdf"</span>
        </div>
      </label>
    </div>

    <v-alert
      v-if="dialogUpload.invalidFormatFile"
      type="warning"
      class="mt-2"
    >
      Arquivo com extensão inválida.
    </v-alert>

    <v-alert
      v-if="dialogUpload.invalidFilesSize"
      type="warning"
      class="mt-2"
    >
      Tamanho do arquivo ultrapassa o limite permitido de 5MB.
    </v-alert>
  </section>
</template>

<script>
  export default {
    name: 'UploadFile',
    delimiters: ['${', '}'],
    props: {
      nameInput: {
        type: Number,
        default: () => (0)
      },
      value: {
        type: File,
        default: () => ({})
      }
    },
    data: () => ({
      dialogUpload: {
        open: false,
        form: {
          files: []
        },
        isLoading: false,
        invalidFilesSize: false,
        invalidFormatFile: false
      }
    }),
    watch: {
      'dialogUpload.form.files': function(newValue, oldValue) {
        this.validateFilesSize(newValue);
        this.validateFormatFile(newValue);
      }
    },
    methods: {
      onChange(event) {
        this.dialogUpload.form.files = [this.$refs.file.files[0]];
        this.$emit('input', this.$refs.file.files[0]);
        this.$emit('change', event);
      },
      remove(file) {
        this.dialogUpload.form.files = [];
        this.$emit('change', this.dialogUpload.form.files);
        this.$emit('input', null);
      },
      dragover(event) {
        event.preventDefault();
        // Add some visual fluff to show the user can drop its files
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.remove('bg-gray-100');
          event.currentTarget.classList.add('bg-green-300');
        }
      },
      dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
      },
      drop(event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
      },
      validateFilesSize(file) {
        if (!file.length) return;
        const fileSize = Math.round(file[0].size / 1024); // converte bytes to kbytes
        const limitFilesSize = 5120; // 5MB
        if (fileSize > limitFilesSize) {
          this.dialogUpload.invalidFilesSize = true;
          this.$emit('input', null);
        } else {
          this.dialogUpload.invalidFilesSize = false;
        }
      },
      validateFormatFile(files) {
        if (!files.length) return;

        const hasFormaInvalid = files.some(
          f => !f.type.includes('application/pdf')
        );

        if (hasFormaInvalid.length) {
          this.dialogUpload.invalidFormatFile = true;
          this.$emit('input', null);
        } else {
          this.dialogUpload.invalidFormatFile = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.p-12 {
  padding: 3rem;
}
.border {
  border-width: 1px;
  border-style: dashed;
  text-align: center;
}
.border-gray-300 {
  border-color: #b8b8b8;
}
.bg-gray-100 {
  background-color: #dbeffd;
}
.w-px {
  width: 1px;
}
.absolute {
  position: absolute;
}
.overflow-hidden {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.h-px {
  height: 1px;
}
.block {
  display: block;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-green-300 {
  background-color: #9ae6b4;
}
.underline {
  text-decoration: underline;
}
.extension {
  font-weight: 700;
  font-size: 11px;
}
</style>
