<template>
  <v-card>
    <v-card-text
      v-if="acompanhamentos && acompanhamentos.data.length"
      class="app-card-content"
    >
      <v-timeline dense>
        <v-slide-x-reverse-transition
          group
          hide-on-leave
        >
          <v-timeline-item
            v-for="item in acompanhamentos.data"
            :key="item.id"
            small
            fill-dot
            :color="
              item.entityType === entityType.SOLICITATION
                ? 'blue-grey darken-1'
                : item.entityType === entityType.PAYMENT
                  ? 'green darken-1'
                  : 'blue darken-2'
            "
          >
            <v-avatar
              slot="icon"
              :color="
                item.entityType === entityType.SOLICITATION
                  ? 'blue-grey darken-1'
                  : item.entityType === entityType.PAYMENT
                    ? 'green darken-1'
                    : 'blue darken-2'
              "
              :size="15"
            >
              <v-icon
                :size="17"
                color="white"
              >
                {{
                  item.entityType === entityType.SOLICITATION
                    ? "schedule"
                    : item.entityType === entityType.PAYMENT
                      ? "attach_money"
                      : "description"
                }}
              </v-icon>
            </v-avatar>

            <v-alert
              :value="true"
              border="left"
              :color="
                item.entityType === entityType.SOLICITATION
                  ? 'blue-grey darken-1'
                  : item.entityType === entityType.PAYMENT
                    ? 'green darken-1'
                    : 'blue darken-2'
              "
              dark
              class="white--text"
            >
              <p class="acompanhamento-text">
                <strong>Ação:</strong>
                <span>{{ item.action | acaoAcompanhamento }}</span>
              </p>
              <p
                v-if="item.entityType === entityType.SOLICITATION"
                class="acompanhamento-text"
              >
                <strong>Status:</strong>
                <span>{{ item.status | statusPedido }}</span>
              </p>
              <p
                v-if="item.entityType === entityType.PAYMENT"
                class="acompanhamento-text"
              >
                <strong>Status:</strong>
                <span>{{ item.status | statusPagamento }}</span>
              </p>
              <p
                v-if="item.entityType === entityType.DOCUMENT"
                class="acompanhamento-text"
              >
                <strong>Status:</strong>
                <span>{{ item.status | statusDocumento }}</span>
              </p>
              <p
                v-if="
                  item.entityType === entityType.DOCUMENT &&
                    item.status === statusDocumentoEnum.REJEITADO
                "
                class="acompanhamento-text"
              >
                <strong>Motivo Rejeição:</strong>
                <span>{{ item.reason }}</span>
              </p>
              <p
                v-if="item.entityType === entityType.DOCUMENT"
                class="acompanhamento-text"
              >
                <strong>Tipo Arquivo:</strong>
                <span>{{
                  item.document.typeDocument
                    ? item.document.typeDocument.name
                    : "-"
                }}</span>
              </p>
              <p
                v-if="item.entityType === entityType.DOCUMENT"
                class="acompanhamento-text"
              >
                <strong>Nome Arquivo:</strong>
                <span>{{ item.document.name }}</span>
              </p>
              <p
                v-if="item.reason"
                class="acompanhamento-text"
              >
                <strong>Razão:</strong>
                <span>{{ item.reason }}</span>
              </p>
              <p class="acompanhamento-text">
                <strong>Criado em:</strong>
                <span>{{ item.createdAt | date("DD/MM/YYYY H:mm:ss") }}</span>
              </p>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-card-text>
    <section
      v-if="acompanhamentos.isLoading"
      class="isLoading"
    >
      <v-progress-circular
        indeterminate
        color="success"
      />
    </section>
  </v-card>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { EntityType } from '@/models/entity-type.enum';
  import { StatusDocumentoEnum } from '@/models/status-documento.enum';

  export default {
    name: 'AcompanhamentoList',
    data: () => ({
      entityType: EntityType,
      statusDocumentoEnum: StatusDocumentoEnum
    }),
    computed: {
      ...mapState('pedido', ['detail']),
      ...mapState('acompanhamentos', ['acompanhamentos'])
    },
    mounted() {
    //this.init();
    },
    destroyed() {
      this.clearAcompanhamentos();
    },
    methods: {
      init() {
        const {
          data: { id }
        } = this.detail;

        const paginage = {
          ...this.acompanhamentos.paginate,
          input: { solicitationId: id },
          inputPage: { ...this.acompanhamentos.paginate.inputPage }
        };

        this.changePageAcompanhamento(paginage);
        this.pageAcompanhamentos();
      },
      ...mapActions('acompanhamentos', {
        changePageAcompanhamento: 'changePageAcompanhamento'
      }),
      ...mapActions('acompanhamentos', {
        pageAcompanhamentos: 'pageAcompanhamentos'
      }),
      ...mapActions('acompanhamentos', {
        clearAcompanhamentos: 'clearAcompanhamentos'
      })
    },
  };
</script>
<style lang="scss" scoped>
.acompanhamento-text {
  font-size: 15px !important;
  strong {
    font-size: 15px !important;
    margin-right: 5px;
  }
}
</style>
