<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="paymentsParent"
      hide-default-footer
    >
      <template v-slot:item.status="{ item }">
        {{
          statusPagamento[`${item.status}`]
        }}
      </template>
      <template v-slot:item.value="{ item }">
        {{
          item.value | currency
        }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle>
          <v-btn
            v-if="item.externalPaymentType==='ticket' && item.status!=='CANCELADO'"
            depressed
            color="blue-grey darken-2"
            small
            class="white--text"
          >
            <a
              :href="item.externalPaymentUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="white--text"
            >
              <v-icon color="white">monetization_on</v-icon>
            </a>
          </v-btn>

          <v-btn
            v-if="item.status==='pending' || item.status=== 'PENDENTE' && !item.parentId"
            depressed
            color="blue-grey darken-2"
            small
            class="white--text"
            @click.prevent="openDialog(item)"
          >
            <v-icon color="white">
              done
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>

    <v-dialog
      v-if="dialog.open"
      v-model="dialog.open"
      width="500"
    >
      <v-form
        ref="form"
        lazy-validation
        class="app-form"
        @submit.prevent="onSubmit"
      >
        <v-card class="app-card mb-0">
          <v-card-title class="app-card-title">
            <h4 class="text">
              <span>Dar baixa no pagamento</span>
            </h4>
          </v-card-title>

          <v-card-text class="app-card-content">
            <v-textarea
              v-model="dialog.form.observation"
              label="Observação"
            />
          </v-card-text>

          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              small
              color="error"
              text
              @click.prevent="closeDialog"
            >
              Fechar
            </v-btn>
            <v-btn
              depressed
              small
              color="success"
              type="submit"
            >
              Confirmar Pagamento
            </v-btn>
          </v-card-actions>
        </v-card>
        <section
          v-if="detail.isLoading"
          class="loading"
        >
          <v-progress-circular
            indeterminate
            color="success"
          />
        </section>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { StatusPagamento }  from '@/models/status-pagamento.enum'
  export default {
    name: 'PaymentsList',
    props: {
      payments: {
        type: Array,
        required: true
      }
    },
    data: () => ({
      headers: [
        {
          text: 'Número',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Valor',
          align: 'start',
          sortable: false,
          value: 'value'
        },
        {
          text: 'Descrição',
          align: 'start',
          sortable: false,
          value: 'description'
        },
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status'
        },
        {
          text: 'Observação',
          align: 'start',
          sortable: false,
          value: 'observation'
        },
        {
          text: 'Ações',
          align: 'start',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ],
      dialog: {
        open: false,
        data: null,
        form: {
          status: 'CONFIRMADO',
          observation: null
        }
      },
      statusPagamento: StatusPagamento
    }),
    computed: {
      ...mapState('pagamento', ['detail']),
      paymentsParent: {
        get: function () {
          return this.payments
        //return this.payments.filter(pay=>pay.parentId==null)
        }
      }
    },
    watch: {
      'detail.isLoading': function(newValue, oldValue) {
        if (!newValue) {
          this.closeDialog();
        }
      }
    },
    methods: {
      openDialog(item) {
        this.dialog = { ...this.dialog, open: true, data: item };
      },
      closeDialog() {
        this.dialog = {
          ...this.dialog,
          open: false,
          data: null,
          form: { status: 'CONFIRMADO', observation: null }
        };
      },
      onSubmit() {
        const isValid = this.$refs.form.validate();

        if (isValid) {
          const { status, observation } = this.dialog.form;
          const data = { ...this.dialog.data, status, observation };
          this.update(data);
        }
      },
      ...mapActions('pagamento', { update: 'UPDATE' })
    },
  };
</script>
